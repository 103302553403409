import { render, staticRenderFns } from "./Record.vue?vue&type=template&id=169d677b&scoped=true"
import script from "./Record.vue?vue&type=script&lang=ts"
export * from "./Record.vue?vue&type=script&lang=ts"
import style0 from "./Record.vue?vue&type=style&index=0&id=169d677b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "169d677b",
  null
  
)

export default component.exports

import { Component, Vue } from 'vue-property-decorator'
import Echarts from 'echarts'
import { dayFormat } from '@/utils/formatDate'
@Component({
  filters: {
    filtersState (value: string) {
      return value === '1' ? '倾斜' : value === '0' ? '正常' : '--'
    },
    filtersDefense (value: number) {
      return value === 0 ? '撤防' : value === 1 ? '布防' : '--'
    }
  }
})
export default class Tilt extends Vue {
  private radio= 'echarts'
  private echarts: any= null
  private searchInfo: any= {
    dateRange: []
  }

  private info = {}

  private page = 1
  private size = 10
  private total = 0
  private tableData =[]
  private loading = false

  created () {
    this.getDate()
  }

  mounted () {
    window.addEventListener('resize', this.resize)
  }

  // 获取七天前时间
  getDate () {
    const date = new Date()
    const time = date.getTime()
    const beforeMs = time - 3600 * 1000 * 24 * 6
    const beforeDate = date.setTime(beforeMs)
    this.searchInfo.dateRange = [dayFormat(beforeDate), dayFormat(date.setTime(time))]
    this.onSearch()
  }

  getData () {
    const info = {
      seedlingId: this.$route.params.seedlingId,
      startTime: this.searchInfo.dateRange[0],
      endTime: this.searchInfo.dateRange[1]
    }
    this.$axios.get(this.$apis.seedling.selectDumpDetail, {
      ...info,
      page: this.page,
      size: this.size
    }).then(res => {
      if (res) {
        this.total = res.total || 0
        this.info = res
        this.page === 1 && this.bloodEchart(res)
      }
    })
  }

  bloodEchart (data: {collectionTimes: string[]; xValue: string[]; yValue: string[]}) {
    this.$nextTick(() => {
      this.echarts = Echarts.init(this.$refs.echarts as any)
      const option = {
        color: ['#FFC117', '#2C8EFF'],
        grid: {
          bottom: 50,
          top: 50,
          left: 30,
          right: 0
        },
        tooltip: {
          trigger: 'axis',
          confine: true,
          textStyle: {
            fontSize: 12
          }
        },
        legend: {
          type: 'plain',
          left: 0,
          top: 0,
          icon: 'rect',
          itemHeight: 2,
          itemWidth: 12,
          itemGap: 16,
          textStyle: {
            color: 'rgba(140, 140, 140, 1)'
          }
        },
        xAxis: {
          type: 'category',
          axisTick: {
            onZero: false
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.26)'
            },
            onZero: false
          },
          axisLabel: {
            show: true,
            margin: 15,
            textStyle: {
              color: 'rgba(0, 0, 0, 0.45)'
            }
          },
          data: data.collectionTimes
        },
        yAxis: {
          type: 'value',
          offset: -2,
          axisLine: {
            show: false,
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.45)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.15)'
            }
          }
        },
        series: [{
          name: 'X轴',
          type: 'line',
          symbol: 'none',
          smooth: true,
          lineStyle: {
            width: 4,
            shadowBlur: 0,
            shadowColor: '#FFC11733',
            shadowOffsetY: 20
          },
          data: data.xValue
        }, {
          name: 'Y轴',
          type: 'line',
          symbol: 'none',
          smooth: true,
          lineStyle: {
            width: 4,
            shadowBlur: 0,
            shadowColor: '#2C8EFF33',
            shadowOffsetY: 20
          },
          data: data.yValue
        }]
      }
      this.echarts.setOption(option)
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  resize () {
    this.echarts && this.echarts.resize()
  }
}
